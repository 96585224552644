import HtmlTooltip from "../styling/htmltooltip";
import Typography from "@material-ui/core/Typography";
import i18n from "../i18n/i18n";
import MenuIcon from "@material-ui/icons/Menu";
import React, {useState} from "react";
import SearchField from './searchfield/searchfield';
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {IconButton} from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import BookmarkIcon from "@material-ui/icons/StarBorder";
import SettingsIcon from "../images/icons/settings";
import CloseIcon from '@material-ui/icons/Home';
import MeasureIcon from "@material-ui/icons/Straighten";
import SvgIcon from "@material-ui/core/SvgIcon";
import {ReactComponent as Ukraine} from "../images/icons/flags/ukraine.svg";
import {ReactComponent as Germany} from "../images/icons/flags/germany.svg";
import {ReactComponent as UK} from "../images/icons/flags/uk.svg";
import { ReactComponent as Italy } from "../images/icons/flags/italy.svg";
import { ReactComponent as France } from "../images/icons/flags/france.svg";

const ExtendedMenu = ({onSearch, onFilter, onPrint, onSettings, onBookmark, onClose, onMeasure, onPresentation, onMore, onLanguage, userLoggedIn, model, timelinevisible, onHighlight, onFilterClicked, substitutionMenu}) => {
  /*const hasNewResource = model.getResourceModel().getAll().filter(
      t => t.id === 0).length > 0;*/
  const [inputValue, setInputValue] = useState("");

  const language =  localStorage.getItem('histomania_language') || "de";

  return <div style={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  }}>
    <div style={{display: "flex", alignItems: 'center'}}>
      <IconButton aria-label={i18n("menu")} onClick={(evt) => {
        onMore(evt)
      }}>
        <MenuIcon fontSize="large"/>
      </IconButton>
    </div>
    {substitutionMenu || <>
        <SearchField model={model}
                     onExtendedSearch={(command) => onSearch(inputValue, command)}
                     onExtendedFilter={(command) => onFilter(inputValue, command)}
                     onHighlight={onHighlight}
                     inputValue={inputValue}
                     onInputChange={setInputValue}
                     width={window.innerWidth > 600 ? 400 : 'calc(100vw - 170px)'}
        />
        <div style={{display: "flex"}}>
          {window.innerWidth >= 1200 && <>
            {model.getResourceModel().size() > 0 && <>
              <HtmlTooltip arrow title={<Typography>{i18n(
                  'measureTooltip')}</Typography>}>
                <span>
                  <IconButton onClick={() => {
                    onMeasure()
                  }} disabled={!timelinevisible}>
                    <MeasureIcon/>
                  </IconButton>
                 </span>
              </HtmlTooltip>
              <HtmlTooltip arrow
                           title={<Typography>{i18n("extendedmenu.js908817077")}</Typography>}>
                <IconButton onClick={() => {
                  onPrint()
                }}>
                  <PrintIcon/>
                </IconButton>
              </HtmlTooltip>
            </>}
            <HtmlTooltip arrow title={<Typography>{i18n("extendedmenu.js336969410")}</Typography>}>
              <IconButton aria-label={i18n("extendedmenu.js336969410")} onClick={() => {
                onBookmark()
              }}>
                <BookmarkIcon/>
              </IconButton>
            </HtmlTooltip>
            <HtmlTooltip arrow title={<Typography>{i18n("extendedmenu.js82030918")}</Typography>}>
              <IconButton aria-label={i18n("extendedmenu.js82030918")} onClick={() => {
                onSettings()
              }}>
                <SettingsIcon/>
              </IconButton>
            </HtmlTooltip>
            <HtmlTooltip arrow title={<Typography>{i18n("extendedmenu.js7002811")}</Typography>}>
              <IconButton aria-label={i18n("extendedmenu.js7002811")} onClick={() => {
                let lang = "de";
                if(localStorage.getItem('histomania_language')) {
                  lang = localStorage.getItem('histomania_language');
                }
                window.open('./help/'+lang+'/index.html','_blank');
              }}>
                <HelpOutlineIcon/>
              </IconButton>
            </HtmlTooltip>

          </>
          }
          <HtmlTooltip arrow title={<Typography>{i18n("extendedmenu.js598943965")}</Typography>}>
            <IconButton aria-label={i18n("extendedmenu.js598943965")} onClick={(evt) => {
              onLanguage(evt)
            }}>
              {language === 'de' && <SvgIcon viewBox="0 0 1000 500">
                <Germany/>
              </SvgIcon>}
              {language === 'en' && <SvgIcon viewBox="0 0 1000 500">
                <UK/>
              </SvgIcon>}
              {language === 'fr' && <SvgIcon viewBox="0 0 1000 500">
                <France/>
              </SvgIcon>}
              {language === 'it' && <SvgIcon viewBox="0 0 1000 500">
                <Italy/>
              </SvgIcon>}
              {language === 'ua' && <SvgIcon viewBox="0 0 1000 500">
                <Ukraine/>
              </SvgIcon>}
            </IconButton>
          </HtmlTooltip>
          {model.getResourceModel().size() > 0 &&
              <HtmlTooltip arrow title={<Typography>{i18n("extendedmenu.js760560118")}</Typography>}>
                  <IconButton onClick={() => {onClose()}}>
                    <CloseIcon/>
                  </IconButton>
              </HtmlTooltip>
          }
        </div>
    </>}
  </div>
}

export default ExtendedMenu;
