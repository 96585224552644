import React from "react";
import Typography from "@material-ui/core/Typography";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ScheduleIcon from "@material-ui/icons/Schedule";
import RoomIcon from "@material-ui/icons/Room";
import Filter from "../images/icons/filter";

const res = {
"newStory": "Nuova hiStory",
"newStoryTooltipHeader": "Crea una nuova hiStory",
"newStoryTooltipBody": "Crea la struttura per il tuo racconto, la timeline e gli eventi.",
"newEventTooltipHeader": "Crea un nuovo evento",
"newEvent": "Nuovo evento",
"newEventTooltipBody1": "Crea un nuovo evento nella timeline.",
"newEventTooltipBody2": "Crea l'evento tramite il menu contestuale ",
"newEventTooltipBody3": "(Clic sinistro su un'area libera)",
"newEventTooltipBody4": " nella timeline, se desideri predefinire immediatamente il momento.",
"search": "Cerca",
"searchEventsAndStories": "Cerca eventi e hiStories",
"searchEventsAndStoriesTooltip1": "- Cerca eventi nella timeline durante la digitazione",
"searchEventsAndStoriesTooltip2": "- Cerca hiStories",
"filter": "Filtra",
"filterEvents": "Filtra eventi",
"filterEventsTooltip1": "Mostra e nasconde eventi in base a criteri diversi",
"measure": "Misura tempi",
"measureTooltip": "Misura tempi nella timeline",
"help": "Aiuto",
"more": "di più...",
"loginInfoNotLoggedIn": "Non sei connesso",
"loginInfoDoLoginHere": "Accedi qui.",
"login": "Accedi",
"loginWithGoogle": "Accedi con Google",
"loginWithFacebook": "Accedi con Facebook",
"loginWithVIDIS": "Accedi con account scolastico (VIDIS)",
"loginWithFobizz": "Accedi con Fobizz",
"loginWithEdulog": "Accedi con account scolastico (edulog)",
"storiesNearby": "hiStories nelle vicinanze",
"searchStories": "Cerca hiStories",
"myStories": "Le mie hiStories",
"recentlySeen": "Visto di recente",
"usedImagesAndSources": "Le immagini utilizzate e le loro fonti.",
"sourcesOnlyGiven": "Le fonti sono fornite solo se l'autore le ha inserite.",
"imageSourceReferences": "Riferimenti alle fonti delle immagini",
"settings": "Impostazioni",
"separatorTimelineDesc": "Separatore per timeline/testo articolo",
"horizontal": "orizzontale",
"vertical": "verticale",
"showSidewaysDuringEventSelection": "mostra lateralmente durante la selezione degli eventi",
"alwaysSuperimpose": "mostra sempre sovrapposto",
"automatic": "automatico",
"detailView": "Vista dettagli",
"eventLabeling": "Etichettatura degli eventi",
"fullLabeling": "etichettatura completa",
"maxAsLongAsBar": "massimo lungo quanto la barra dell'evento",
"showTimelineBackgroundImage": "Immagine di sfondo della timeline",
"showTimelineBackgroundImageTrue": "mostra",
"showTimelineBackgroundImageFalse": "non mostrare",
"timelineTheme": "Tema della timeline",
"dark": "scuro",
"bright": "chiaro",
"chooseStory": "Scegli hiStory",
"close": "Chiudi",
"save": "Salva",
"ok": "OK",
"cancel": "Annulla",
"yes": "Sì",
"no": "No",
"delete": "Elimina",
"copy": "Copia",
"edit": "Modifica",
"back": "Indietro",
"understoodAndSave": "Compreso e salva",

  "pastOrFutureDuration": (isPast, duration) => ((isPast ? "fa " : "in ") + duration),
  "startType": "Tipo di inizio",
  "endType": "Tipo di fine",
  "pointInTime": "Momento",
  "born": "nato",
  "earliest": "al più presto",
  "start": "Inizio",
  "origin": () => (<span><b>Origine</b><br/><Typography variant="caption">oppure fondazione/creazione</Typography></span>),
  "discovery": "Scoperta",
  "publication": "Pubblicazione",
  "firstDescription": "Prima descrizione",
  "baptized": "battezzato",
  "premiere": "Prima rappresentazione",
  "opened": "aperto",
  "activeSince": "attivo dal",
  "died": "morto",
  "latest": "al più tardi",
  "end": "Fine",
  "decommissioning": "Messa fuori servizio",
  "dissolved": "sciolto",
  "lost": "disperso dal",
  "activeUntil": "attivo fino al",
  "discontinued": "interrotto",
  "closed": "chiuso",
  "precision": "Precisione",
  "1000000000years": "1 Mld anni",
  "100000000years": "100 Mio anni",
  "10000000years": "10 Mio anni",
  "1000000years": "1 Mio anni",
  "100000years": "100 mila anni",
  "10000years": "10 mila anni",
  "millenium": "Millennio",
  "century": "Secolo",
  "decade": "Decennio",
  "year": "Anno",
  "month": "Mese",
  "day": "Giorno",
  "hour": "Ora",
  "minute": "Minuto",
  "exact": "esatto",
  "duration": "Durata",
  "years": "Anni",
  "mon.": "Mesi",
  "h.": "Ore",
  "min.": "Min.",
  "noTimeChosen": "Nessuna data selezionata",
  "today": "oggi",

  "weekdays": () => (["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"]),
  "internalServerError": () => (<div>Si è verificato un errore interno del server.<br/>Si prega di contattare un referente di histomania.</div>),
  "userNotAcknowledged": () => (<div>L'utente è presente nel nostro sistema, ma non è stato ancora confermato da te tramite il link nell'e-mail.</div>),
  "userOrPwdNotInSystem": () => (<div>L'utente o la password inserita non sono conosciuti.</div>),
  "oldPasswordUnknown": () => (<div>La vecchia password non è conosciuta.</div>),
  "pwdGuideline": () => (<div>I dati inseriti non rispettano le linee guida.</div>),
  "noDataRefresh": () => (<div>Impossibile aggiornare i dati.</div>),
  "emailAlreadyAvailable": () => (<div>L'indirizzo e-mail inserito è già disponibile</div>),
  "userNotCreated": () => (<div>L'utente non è stato creato</div>),
  "reCaptchaError": () => (<div>Google reCaptcha: Non sei stato identificato come umano.</div>),
  "notPlausible": () => (<div>Le informazioni inserite non sono plausibili.</div>),
  "sessionInvalid": () => (<div>La sessione non è più valida.<br/>Per favore accedi di nuovo.</div>),
  "unknownError": (errorCode) => (<div>Si è verificato un errore sconosciuto con il numero {errorCode}.<br/>Si prega di contattare un referente di histomania.</div>),
  "hideStory": () => (<><Typography color="inherit">Nascondi hiStory</Typography>
    <hr/>
    Puoi ritrovare rapidamente questa hiStory con la ricerca sotto <br/><em>'visti di recente'</em>.</>),
  "showStory": () => (<><Typography color="inherit">Mostra hiStory</Typography>
    <hr/>
    Apri la hiStory in una nuova scheda e aggiungi la timeline in parallelo alle timeline esistenti.</>),
  "showStory2": "Mostra hiStory",
  "restrictYourSearch": (totalcnt) => ("Troppi risultati (" + totalcnt + "). Restringi ulteriormente la ricerca."),
  "allowGeoLocation": "Per favore consenti nelle impostazioni che histomania possa accedere alla tua posizione.",
  "everytime": "in qualsiasi momento",
  "everywhere": "ovunque",
  "myNeighbourhood": "nel mio quartiere",
  "inNeighbourhood": "nelle vicinanze di",
  "search2": "Cerca",
  "currentView": () => (<>vista<br/>corrente</>),
  "recentlySeen2": () => (<>visti di recente</>),
  "myStories2": () => (<>le mie<br/>hiStories</>),
  "searchPhrase": "Parole chiave di ricerca",
  "inputSearchPhrase": "Inserisci parola chiave",
  "searchPhraseSample": "ad es.: Fisico Albert",
  "minimumCharacters": (minSearchWordCharCnt) => ("almeno " + minSearchWordCharCnt + " caratteri per parola chiave"),
  "when": "Periodo dell'evento",
  "where": "Luogo",
  "inNeighbourhoodOf": "nelle vicinanze di ...",
  "chosenPosition": "posizione scelta",
  "latitude": "Latitudine",
  "longitude": "Longitudine",
  "searchTimelines": "Cerca hiStories",
  "searchStoriesInNeighbourhood": "Cerca hiStories nelle vicinanze",
  "resultsFromLocalSearch": "Eventi dalla ricerca locale (clicca per dettagli):",
  "recommended": "hiStories consigliate",
  "todaysBirthdays": "Compleanni di oggi",
  "todaysDeathdays": "Anniversari di morte di oggi",
  "anniversaries": "Anniversari di eventi oggi",
  "hideAll": "Nascondi tutto",
  "inCurrentView": "nella vista attuale",
  "privateTimeline": "hiStory privata",
  "less": "meno...",
  "image": "Immagine",

  "logoURL": "https://" + window.location.host,
  "wikiLicenseCCSA": (site) => (<>Fonte: de.wikipedia.org - Il testo è disponibile sotto la licenza <a target="_blank" rel="noopener noreferrer" href={"https://de.wikipedia.org/wiki/Wikipedia:Lizenzbestimmungen_Creative_Commons_Attribution-ShareAlike_3.0_Unported"}>„Creative Commons Attribution/Share-Alike“</a>.<br/>Qui puoi trovare l'elenco degli <a target="_blank" rel="noopener noreferrer" href={"https://xtools.wmflabs.org/articleinfo-authorship/de.wikipedia.org/" + site + "?uselang=de"}>autori</a>.</>),
  "wikiURL": "https://de.wikipedia.org/wiki/",
  "infiniteCalendarHeaderFormat": "dddd, D MMM",
  "resetfilter": "Reimpostare il filtro",
  "maintainEventList": "Gestisci gli eventi in forma tabellare",
  "importcsv": "Importa da CSV",
  "import": "Importare",
  "prenameError": "Inserisci il tuo nome senza numeri o caratteri speciali. Un nome deve avere almeno due caratteri.",
  "surnameError": "Inserisci il tuo cognome senza numeri o caratteri speciali. Un cognome deve avere almeno due caratteri.",
  "mailError": "Si prega di inserire un indirizzo e-mail valido, in modo che possiamo inviarti un link di conferma.",
  "fonts": "Caratteri...",
  "bold": "grassetto",
  "italic": "corsivo",
  "underline": "sottolineato",
  "code": "Codice",
  "unorderedList": "elenco puntato",
  "orderedList": "elenco numerato",
  "quote": "Citazione",
  "codeblock": "Blocco di codice",
  "divider": "Divisore orizzontale",
  "alignment": "Allineamento del testo...",
  "addImage": "Aggiungi immagine",
  "addVideo": "Aggiungi video",
  "addMap": "Aggiungi mappa",
  "addPanorama": "Aggiungi vista a 360°",
  "addQuiz": "Aggiungi quiz",
  "addInfoblock": "Aggiungi blocco informativo",
  "addLink": "collega il testo selezionato al sito web",
  "addEvent": "collega il testo selezionato all'evento nella cronologia",
  "addStory": "collega il testo selezionato a hiStory",
  "addFilter": "collega il testo selezionato al filtro",
  "landingpage_title": "La piattaforma per la storia su una linea temporale",
  "welcome.js735054413": "Scopri il mondo della storia in hiStories, la collezione interattiva e multimediale di cronologie, testi, immagini, video, quiz, viste a 360° e mappe.",
  "welcome.js142706916": "Crea le tue linee temporali per il tuo curriculum, una cronaca locale o supporto per l'apprendimento in un'esperienza indimenticabile.",
  "welcome.js737110502": "nella mia area",
  "welcome.js455445377": "esplora",
  "welcome.js695746560": "Scopri il mondo della storia in hiStories, la collezione interattiva e multimediale di timeline, testi, immagini e quiz.",
  "explanation": () => (<>
    Vivi la storia in un modo nuovo con histomania, una piattaforma interattiva che rende facile raccontare e comprendere le storie.
    <br/>
    <br/>
    Non perdere mai di vista il tempo e il luogo mentre navighi tra migliaia di storie e biografie.
    <br/>
    A differenza delle narrazioni ipertestuali classiche, dove si può solo passare da un articolo all'altro, i testi in histomania contengono link aggiuntivi che permettono di seguire eventi su una linea temporale o su una mappa.
    <br/>
    <br/>
    <PostAddIcon/>&nbsp;&nbsp;apre una nuova hiStory
    <br/>
    <ScheduleIcon/>&nbsp;&nbsp;mostra l'evento nella linea temporale
    <br/>
    <RoomIcon/>&nbsp;&nbsp;mostra la posizione dell'evento nella mappa
    <br/>
    <Filter/>&nbsp;&nbsp;filtra eventi nella timeline e nella cronologia
    <br/>
    <br/>
    Esplora le dimensioni dei periodi di tempo confrontando le linee temporali di diverse hiStories e misurando le distanze tra eventi all'interno di queste linee temporali.
    <br/>
    Attraverso il confronto e la misurazione ti sarà possibile scoprire i periodi e le epoche della storia in un modo nuovo.
    <br/>
    <br/>
    Usa mappe interattive che oltre a pin per le posizioni possono includere catene di frecce per rotte di viaggio e sovrapposizioni per la rappresentazione di aree.
    <br/>
    Queste possono addirittura essere animate e avere un livello di trasparenza a tua scelta, permettendoti una comprensione unica delle modifiche dei confini.
    <br/>
    <br/>
    Scopri storie nel tuo attuale ambiente o mentre sei in una città straniera semplicemente con un clic.
    Trova le storie giuste nei musei tramite un codice QR.
    <br/>
    <br/>
    Puoi persino integrare le tue hiStories preferite nel tuo sito web personale.
    <br/>
    <br/>
    Che tu voglia pubblicare le tue hiStories o semplicemente navigare nella storia, histomania è il compagno ideale per studenti di storia, studiosi di storia, storici, cronisti locali e tutti coloro che trovano semplicemente piacere nella storia o negli eventi attuali di politica e cronaca mondiale.
  </>),
  "topfacts_wiki": () => (<span>Esiste un articolo su Wikipedia o video su YouTube per una storia o un evento storico?<br/>O se un evento è geograficamente determinato, vuoi vederlo su una mappa di Google Maps ed essere eventualmente guidato lì?<br/>Con un clic, vai dai dettagli in histomania alla pagina corrispondente.</span>),
  "topfacts.js415020171": "navigare",
  "topfacts.js111683501": "confrontare",
  "topfacts.js56475656": "integrare",
  "topfacts.js939157228": "gratuito",
  "topfacts.js617723463": "misurare",
  "topfacts.js692398414": "creare",
  "topfacts.js899063949": "domande immaginarie",
  "topfacts.js152860411": "condividere",
  "topfacts.js55830277": "collegato",
  "topfacts.js335278155": "cercare",

  "topfacts.js150418448": "indipendente dal dispositivo",
  "topfacts.js820645511": "dal Big Bang alla fine del mondo",
  "topfacts.js645936163": "Collegando eventi storici, puoi navigare da una storia all'altra.",
  "topfacts.js751322491": "histomania ti permette di sovrapporre diversi cronogrammi, rendendoli confrontabili.",
  "topfacts.js921529656": "Vuoi integrare storie nel tuo sito web o blog? Scopri di più qui.",
  "topfacts.js895080977": "histomania è gratuito nella versione base. Indipendentemente dal fatto che lo utilizzi per scopi privati, commerciali o educativi.",
  "topfacts.js948823105": "Quanti anni ha Marilyn Monroe più di James Dean? Quanti anni aveva Einstein quando ha ricevuto il Premio Nobel? Misura i tempi che ti interessano sul cronogramma con il calibro integrato.",
  "topfacts.js107256625": "Scrivi la tua storia. Presenta eventi storici mettendo in luce ciò che ritieni importante. Oppure descrivi il tuo curriculum su un cronogramma.",
  "topfacts.js102762366": "E se... fossi nato nello stesso periodo di Goethe? Crea il tuo curriculum e spostalo in modo da confrontarlo con quello di altri.",
  "topfacts.js373990748": "Lavora insieme ad altri su un cronogramma e decidi, tramite permessi, chi può vedere o modificare la tua storia.",
  "topfacts.js809530473": "Oltre alla ricerca per parole chiave, puoi cercare cosa è successo in determinati periodi in certi luoghi. Sei in un posto sconosciuto e vuoi sapere di più sulla storia della zona? Clicca su 'Cosa c'è nelle vicinanze?' e usa uno smartphone con GPS per cercare eventi nei dintorni.",
  "topfacts.js15371141": "Sia su uno smartphone, un tablet o un PC. Con histomania hai un'applicazione che si adatta a tutti i dispositivi, sia per dimensione dello schermo che per tipo di input tramite mouse o gesti touch.",
  "topfacts.js678201177": "histomania può gestire periodi di tempo incredibilmente grandi con precisione regolabile fino al minuto. Pertanto, sia le ere geologiche che la storia del primo sbarco sulla luna possono essere rappresentate in modo significativo.",
  "contact": "Contatto",
  "phone": "Telefono",
  "newHiStoryMessage": () => (<span>La tua nuova hiStory è stata creata.<br/>Ora aggiungi eventi.</span>),
  "histomaniamain.js218992075": "Si prega di indicare almeno un amministratore.",
  "menu.js844059924": "Nuova hiStory",
  "storydetailsmain.js785302558": "nuova hiStory",
  "storydetailsmain.js382589702": "hiStory",
  "mainFab.js309680386": 'modificare hiStory',
  "mainFab.js593675655": 'Gestisci eventi nella tabella',
  "mainFab.js968262525": 'Aggiungi evento',
  "mainFab.js943257132": 'nuova hiStory',
  "bookmarks.js93045028": "Segnalibri",
  "menu.js505082777": "Nuovo evento",
  "menu.js756551371": "E se...?",
  "menu.js663038438": "Annulla e se...",
  "menu.js439620799": "Segnalibri",
  "menu.js157014000": "Stampa ed esportazione immagini",
  "menu.js555525792": "Importa/Esporta",
  "menu.js923812869": "Gestione profilo",
  "menu.js450602741": "Il mio profilo",
  "menu.js984392851": "Cambia password",
  "menu.js498764961": "Lingua",
  "menu.js869701443": "Tedesco",
  "menu.js503793771": "Inglese",
  "menu.js161223496": "Ucraino",
  "menu.js961199351": "Le mie impostazioni",
  "menu.js165576261": "Disconnettiti",
  "menu.js972296370": "Accedi",

"bargroup.js313011152": "Denominazione",
"bargroup.js999078620": "nuova denominazione",
"barshape.js677799618": "barra grassa",
"barshape.js210728036": "barra sottile",
"barshape.js708427194": "parentesi graffa",
"barshape.js853386386": "sfondo trasparente",
"barshape.js709767519": "piccola stella",
"barshape.js620435244": "stella",
"barshape.js170641551": "piccolo cerchio",
"barshape.js649456610": "cerchio",
"barshape.js494726977": "nuvola",
"barshape.js735933567": "fumetto",
"barshape.js931763133": "testo al centro",
"barshape.js931763134": "solo linea di base",
"barshape.js509994468": "piccolo documento",
"barshape.js848987645": "documento",
"barshape.js786118528": "piccolo sole",
"barshape.js560723628": "sole",
"barshape.js624588809": "piccola croce",
"barshape.js33818872": "croce",
"barshape.js597772577": "piccola freccia sinistra",
"barshape.js170062957": "freccia sinistra",
"barshape.js731660847": "piccola freccia destra",
"barshape.js233359132": "freccia destra",
"dataset.js729839193": "Errore nella descrizione",
"dataset.js345733057": "Denominazione",
"dataset.js850812704": "Unità",
"descriptionBlock.js240405443": "Descrizione",
"descriptionBlock.js884402762": "Qui puoi anche inserire immagini e video.",
"designBlock.js426292766": "Design",
"designBlock.js902377753": "Così appare l'evento nella timeline.",
"imageBlock.js428756050": "Immagine",
"imageBlock.js604367201": "Questa immagine appare nella timeline. Puoi inserire altre immagini nella descrizione.",
"imageBlock.js399000806": "Licenza dell'immagine",
"imagechooser.js38403923": "Licenza dell'immagine",
"innerevents.js238036015": "Tempi interni",
"sourcereferenceblock.js99152413": "Riferimenti delle fonti",
"sourcereferenceblock.js484508229": "Elenca qui la/le fonte/i che confermano l'evento.",
"timeBlock.js186865327": "Tempo",
"timeBlock.js929675741": "Metti un meno davanti all'anno per i tempi pre-cristiani, ad es. 21.04.-753",
"timeBlock.js910792715": "Momento",
"timeBlock.js134397445": "Periodo",
"titleBlock.js239194644": "Titolo",
"titleBlock.js47802846": "Puoi usare più righe.",
"wikipediareference.js851003152": "Pagina di Wikipedia in tedesco (o prefisso con il codice del paese tra parentesi quadre, ad esempio [uk])",
"bookmarkitem.js608408822": "Elimina",
"story.js915248213": "La mia hiStory",
"drafteditortoolbar.js858681026": "Carattere",
"drafteditortoolbar.js823346092": "Paragrafo",
"drafteditortoolbar.js97337207": "Media",
"drafteditortoolbar.js82711167": "Collegamenti",
"chooseImageDialog.js542504966": "Titolo",
"chooseMapDialog.js901660970": "Denominazione della mappa",
"chooseMapDialog.js589922616": "Denominazione",
"chooseMapDialog.js87695793": "URL di Google Maps",
"chooseMapDialog.js10384243": "Descrizione e licenza dell'immagine",
"chooseStreetViewDialog.js854616579": "Titolo",
"chooseStreetViewDialog.js119725327": "Codice embed di Streetview",
"chooseVideoDialog.js778434054": "Titolo",
"chooseVideoDialog.js671606307": "URL o ID di YouTube",
"chooseVideoDialog.js799772333": "Inizio al secondo",
"AddVideoButton.js665672521": "Crea una timeline gratuitamente",
"maintenanceEventList.js998639033": "Eventi",
"filtertimeline.js710501750": "Filtrare eventi",
"searchfield.js825023426": "Cerca hiStories con questo termine",
"searchfield.js18900516": "HiStories intorno a me",
"searchfield.js363834300": "Cerca hiStories per tempo e luogo",
"searchfield.js644144191": "La mia hiStories",
"searchfield.js660560315": "HiStories viste di recente",
"searchfield.js961730789": "Filtra la timeline attuale con questo termine",
"searchfield.js867502466": "Filtra la timeline attuale",
"searchfield.js829902906": "Suggerimenti di ricerca",
"histomaniamain.js781485964": "Il mio evento",

"histomaniamain.js702299662": "Per favore apri o crea prima una hiStory per aggiungere l'evento.",
"histomaniamain.js197615179": "Attenzione durante la condivisione del tuo URL.\nLa tua hiStory è privata e non può essere vista da tutti i destinatari.",
"histomaniamain.js12025145": "Per favore fornisci un titolo di almeno tre lettere per la tua hiStory.",
"histomaniamain.js310062970": "Per salvare la hiStory a lungo termine devi accedere.",
"histomaniamain.js5248846": "Per favore apri prima una hiStory che puoi modificare per copiare lì l'evento.",
"histomaniamain.js513585760": "vai a",
"import.js261569334": "Per favore apri prima una hiStory per depositare lì gli eventi.",
"import.js67411235": "Scegli la hiStory in cui depositare gli eventi.",
"login.js758426829": "Accedi",
"login.js779088926": "E-mail",
"login.js163307733": "Password",
"login.js293038552": "rimani connesso",
"login.js116720706": "Non hai un account?",
"login.js319559879": "Nome",
"login.js278946069": "Per favore inserisci un nome",
"login.js897560837": "Cognome",
"login.js237687098": "Per favore inserisci un cognome",
"login.js457756656": "E-mail",
"login.js692708124": "Per favore inserisci un nome utente valido",
"login.js368777066": "Hai dimenticato la password?",
"login.js327950210": "E-mail",
"login.js493155713": "Login",
"editMediaDialog.js254758074": "Elenco dei media",
"editMediaDialog.js106456795": "JSON",
"markdowneditfield.js110445871": "Modifica vista panoramica",
"markdowneditfield.js117272661": "Modifica quiz",
"markdowneditfield.js848013488": "Modifica elemento multimediale",
"lazygooglemap.js947359452": "Accetto che mi vengano mostrati contenuti di Google Maps. Ciò potrebbe comportare la trasmissione di dati personali a terzi.",
"QuizEditorTitlePage.js823814415": "Titolo",
"menu.js893403567": "Condividi",
"menu.js932778276": "come E-mail",
"menu.js556545983": "Esporta dati",
"menu.js413767225": "Importa dati",
"menu.js990897862": "Aiuto",
"menu.js171939370": "da",
"print.js570625276": "La larghezza minima è",
"print.js612051645": "La larghezza massima è",
"print.js574630162": "L'altezza minima del bar è",
"print.js760791437": "L'altezza massima del bar è",
"print.js758964558": "È stato superato lo sporgenza massima.",
"print.js375853243": "La sporgenza non può essere negativa.",
"print.js15990392": "Deve esserci almeno una parte orizzontale per la stampa.",
"print.js556329669": "Deve esserci almeno una parte verticale per la stampa.",
"print.js833055774": "L'immagine non può essere divisa in più di 100 parti.",
"printdialog.js85755474": "Sporgenza a causa delle etichette (",
"printdialog.js341020472": "sfondo trasparente",
"printdialog.js665169354": "Disegna ombre",
"printdialog.js605134089": "orizzontale",
"printdialog.js839026585": "verticale",
"displaystorydetails.js158045992": "Fonti",
"addstorydescriptor.js92624992": "hiStories incorporate",
"addstorydescriptoreditdialog.js502574362": "Gruppo",
"addstorydescriptoreditdialog.js124818429": "ID della hiStory",
"addstorydescriptoreditdialog.js117860561": "ID eventi non filtrati",
"addstorydescriptoreditdialog.js777122119": "Posizione",
"backgroundimageinput.js816937029": "Immagine",
"backgroundimageinput.js218259721": "Questa immagine funge da sfondo per la timeline e appare nella ricerca. Puoi inserire altre immagini nella descrizione.",
"backgroundimageinput.js429573179": "Licenza dell'immagine",
"userrightsdialog.js70424287": "Cerca utenti",
"userrightsdialog.js480394996": "E-mail",
"userrightsdialog.js216158241": "Per favore inserisci un indirizzo e-mail valido",
"bookingrightsadministration.js589033383": "Autorizzazioni",
"bookingrightsadministration.js546709873": "chiunque può leggere la hiStory",
"descriptioninput.js517543907": "Descrizione",
"dummyuserhint.js499234330": "Non hai effettuato l'accesso",
"headlineinput.js150642075": "Titolo",
"headlineinput.js593049979": "Titolo della hiStory",
"headlineinput.js477282705": "Sottotitolo",
"newstoryhint.js799008560": "Primi passi",
"sourcereferenceinput.js783166353": "Riferimenti delle fonti",
"wikipediainput.js705594377": "Contenuti avanzati",
"search.js726564732": "Cerca",
"search.js671154469": "in qualsiasi momento",

"durationpanel.js274332505": "durata sconosciuta",
"searchfield.js807364549": "Cerca nella timeline attuale",
"histomaniamain.js734544182": "La hiStory verrà salvata solo per la durata della tua sessione.",
"menu.js698756284": "Eventuali modifiche fatte a titolo di prova finora verranno ripristinate",
"userrightsdialog.js937814759": "Modifica autorizzazione",
"bargroup.js45524065": "Modifica etichetta ovunque",
"sourceReferenceBlock.js304249147": "Indica le fonti per l'evento.",
"timeBlock.js20893151": "Tempi interni...",
"wikipediareference.js907588328": "Voglio incorporare un articolo di Wikipedia.",
"bookmarkdialog.js412170740": "Aggiungi segnalibro",
"bookmarkdialog.js929413458": "Non sei connesso. I tuoi segnalibri rimarranno validi solo per circa un giorno.",
"bookmarks.js475765892": "Salva le timeline aperte come segnalibro",
"bookmarks.js306859711": "Vuoi davvero cancellare?",
"cookieconsent.js905029185": "Utilizziamo cookie ed elementi localStorage. Puoi modificare le tue impostazioni in qualsiasi momento tramite il menu nell'app. Se non desideri accettare tutti i cookie o gli elementi localStorage, o se desideri saperne di più, fai clic su 'Mostra di più'.",
"cookieconsent.js352836975": "Mostra di più",
"cookieconsent.js243259253": "Chiudi",
"cookieconsent.js645797755": "Accetta tutti i cookie",
"schoolcookieconsent.js905029185": () => <>Su schule.histomania.com utilizziamo solo cookie tecnicamente necessari e voci localStorage, che non possono essere rifiutati.<br/>Questi includono un cookie di sessione e voci localStorage che memorizzano la selezione della lingua, l'identificazione per l'utente in modo che non debba eseguire l'accesso ripetutamente, nonché la conferma di questa finestra.<br/></>,
"schoolcookieconsent.js645797755": "OK",
"drafteditortoolbar.js747935826": "Carattere",
"drafteditortoolbar.js616605922": "Paragrafo",
"drafteditortoolbar.js894699961": "Media",
"drafteditortoolbar.js109669568": "Link",
"chooseMapDialog.js417538297": "Fase 2:",
"chooseMapDialog.js561133791": "Fase 3:",

"chooseMapDialog.js61491380": "applica",
"Link.js291014714": "apri hiStory",
"Link.js62298905": "Filtra eventi nella Timeline",
"maintenanceeventlisttable.js380608930": "Denominazione",
"maintenanceeventlisttable.js866297000": "Inizio",
"maintenanceeventlisttable.js573824846": "Fine",
"filtertasklist.js930196063": "Tutti",
"filtertasklist.js18265885": "Tutti gli eventi",
"filtertasklist.js560877919": "Termine",
"filtertasklist.js132146692": "Periodo",
"filtertasklist.js8240783": "Proprietà",
"filtertasklist.js192609356": "Eventi puntuali",
"filtertasklist.js774361819": "Eventi continuati",
"filtertasklist.js700792519": "Gruppi",
"filtertasklist.js482827431": "Eventi singoli",
"extendedmenu.js908817077": "Stampa ed esportazione immagini",
"extendedmenu.js336969410": "Segnalibri",
"extendedmenu.js82030918": "Impostazioni",
"extendedmenu.js7002811": "Aiuto",
"extendedmenu.js598943965": "Lingua",
"extendedmenu.js760560118": "Home",
"histomaniamain.js114097156": "Timeline completa nell'area visibile",
"import.js191606136": "Importazione CSV",
"import.js112638704": "Copia il contenuto del tuo file CSV nel campo di testo e clicca su 'Importa'.",
"login.js382006560": "Iscriviti anche alla newsletter di histomania.",
"login.js416865926": "Termini di utilizzo",
"login.js64439934": "Informativa sulla privacy",
"login.js572661779": "Ho letto e accetto i termini di utilizzo e l'informativa sulla privacy",
"login.js230957075": "Crea un account",
"login.js878212959": "Invia nuova password",
"editMediaDialog.js808123558": "Media",
"markdowneditfield.js554353551": "Caratteri",
"markdowneditfield.js558691581": "Intestazione 1",
"markdowneditfield.js785963602": "Intestazione 2",
"markdowneditfield.js935699377": "Intestazione 3",
"markdowneditfield.js534085527": "Intestazione 4",
"markdowneditfield.js518723970": "Intestazione 5",
"markdowneditfield.js452157683": "Intestazione 6",

"markdowneditfield.js267080676": "Corsivo (testo selezionato)",
"markdowneditfield.js267174421": "Grassetto Corsivo (testo selezionato)",
"markdownhelper.js160663836": "Impossibile trovare l'elemento multimediale",
"markdownhelper.js744897963": "Filtro",
"markdownhelper.js297030165": "Mostra luogo",
"markdownhelper.js702490738": "apri sito web esterno",
"quizErrorDialog.js818208140": "È richiesto un titolo per il quiz.",
"quizErrorDialog.js316989684": "Per favore, fornisci almeno una domanda.",
"quizErrorDialog.js217047946": "Domanda #{index+1}: Fornisci la domanda.",
"quizErrorDialog.js592184179": "Domanda #{index+1}: Fornisci al massimo quattro risposte.",
"quizErrorDialog.js91808612": "Domanda #{index+1}: Seleziona almeno una risposta corretta tramite le caselle di controllo.",
"quizErrorDialog.js770785722": "Domanda #{index+1}: Fornisci almeno una risposta.",
"quizErrorDialog.js121149031": "Domanda #{index+1}, Risposta #{answerIndex+1}: Una risposta vuota non è consentita.",
"lazygooglemap.js821091857": "Purtroppo, in questo sottodominio non possono essere mostrati contenuti di Google Maps per motivi di privacy.",
"media.js334165907": "Errore nella descrizione della mappa",
"media.js745432641": "Errore nella descrizione della mappa",
"QuizEditorTitlePage.js567873608": "Pagina iniziale",
"QuizEditorTitlePage.js3575109": "Descrizione",
"QuizQuestion.js522602069": "Formula qui la tua domanda",
"QuizQuestion.js277305215": "Aggiungi risposte e seleziona quelle corrette",
"QuizQuestion.js719954254": "Qui si può aggiungere una spiegazione alla risposta",
"QuizQuestion.js631196400": "Quanti punti assegnare per una risposta corretta",
"streetview.js903524730": "Purtroppo, in questo sottodominio non possono essere mostrati contenuti di Google Maps per motivi di privacy.",
"youtube.js541386012": "Purtroppo, in questo sottodominio non possono essere mostrati contenuti di YouTube per motivi di privacy.",
"circularmenu.js381995722": "Cerca",
"circularmenu.js524039849": "Misura intervalli di tempo nella timeline",
"circularmenu.js739065959": "Impostazioni personalizzate",
"circularmenu.js357509198": "Segnalibri",
"circularmenu.js636940232": "Espandi/comprimi tutti i gruppi",
"circularmenu.js355370525": "Crea una nuova hiStory",
"circularmenu.js753068960": "Crea un evento a punto temporale",
"circularmenu.js653781889": "Crea un evento a intervallo temporale",
"menu.js826958696": "Informativa sulla privacy",
"menu.js586813367": "Termini e condizioni d'uso",
"menu.js614038001": "Impressum",
"menu.js439856996": "Licenze immagini",
"menu.js942699906": "Ripristina impostazioni cookie",
"printdialog.js317202399": "Risoluzione",
"printdialog.js176250960": "Intervallo di tempo",
"printdialog.js161420731": "Impostazioni immagine",
"printdialog.js399246718": "Condividi immagine",
"printdialog.js150161449": "Anteprima",
"printdialog.js8964786": "Stampa",
"printdialog.js544885797": "Esporta immagine",

"ratings.js661212453": "Per poter valutare devi effettuare il login.",
"displaystorydetails.js121722705": "Visualizza le licenze delle immagini",
"bookingrightsadministration.js131215424": "Questi utenti possono modificare la hiStory:",
"dummyuserhint.js706662830": ()=><>Senza registrazione puoi creare una hiStory solo a <b>scopo di test</b>, ma verrà eliminata dopo un po' di tempo.</>,
"newstoryhint.js223326167": () => (<>Qui racconti la tua hiStory, che fornisce la struttura per la tua timeline.<br/>Per ora è sufficiente dare un titolo alla tua hiStory e salvarlo.<br/>Dopodiché puoi iniziare a inserire i singoli eventi.</>),
"bookingrights.js286057285": "La hiStory può essere modificata da ",
"distributerightsdialog.js87761818": "Distribuisci diritti",
"barexpansion.js878339034": "Altezza",
"barexpansion.js887101724": "-volte",
"barexpansion.js728758094": "Imposta l'altezza visualizzata dell'evento come multiplo dell'altezza normale.",
"bargroup.js152857522": "Gruppo",
"bargroup.js914347576": "Cambia etichetta",
"barposition.js84416775": "Posizione",
"barposition.js560624624": "Determina tramite la posizione quali eventi dovrebbero essere visualizzati più in basso e quali più in alto.",
"barsettings.js434654385": "Colore",
"barsettings.js408327828": "Scegli il colore per l'evento. Il colore del testo verrà selezionato automaticamente.",
"barshape.js315964835": "Forma",
"barshape.js230080019": "Scegli la forma della barra dell'evento.",
"dataset.js205796920": "Dati del diagramma",
"dataset.js666145317": "Descrivi diagrammi temporali.",
"dataset.js354018274": "Inserisci qui il contenuto CSV e premi OK:",
"dataset.js218289977": "Importa",
"editbookingdetails.js985514551": "Vuoi davvero eliminare l'evento?",
"imagechooser.js862610110": "Immagine ad evento nella timeline",
"imagechooser.js871519548": "Scegli un'immagine che appare nella timeline.",
"imagechooser.js950070103": "Puoi aggiungere altre immagini nella descrizione.",
"wikipediareference.js272862577": "Riferimento Wikipedia",
"wikipediareference.js904830442": "Riferimento a Wikipedia.",
"bookmarks.js523603774": "Vuoi davvero eliminare il segnalibro '{deleteBookmarkS && deleteBookmarkS.name}'?",
"chooseMapDialog.js720327007": "Specifica l'etichetta.",
"chooseMapDialog.js760709110": "Clicca quindi sulla mappa per impostare un nuovo marcatore o inserisci l'URL di Google Maps.",
"chooseMapDialog.js388797867": "Specifica la descrizione dell'immagine e la licenza, se la tua immagine non è gratuita.",
"chooseMapDialog.js842057153": "Adatta la dimensione nella mappa. Aggiungi punti di controllo (almeno tre) con [Ctrl + Click].",
"chooseMapDialog.js982829124": "Quindi scegli ",
"Link.js776621301": "Evento nella timeline",
"Link.js501324063": "Apri un'altra hiStory.",
"import.js93314536": "Importa da JSON",
"import.js254441848": "Importa da campo di testo",


  "login.js15485281": "Per favore inserisci l'indirizzo email registrato su histomania:",
  "markdowneditfield.js426937653": "Grassetto (testo selezionato)",
  "markdowneditfield.js85888028": "Inserisci immagine",
  "markdowneditfield.js249402737": "Inserisci video",
  "markdowneditfield.js421012641": "Inserisci mappa",
  "markdowneditfield.js535534111": "Inserisci vista panoramica",
  "markdowneditfield.js58232052": "Inserisci filtro",
  "markdowneditfield.js954635745": "Inserisci quiz",
  "markdowneditfield.js827493202": "Elenco di tutti i media",
  "markdownhelper.js904359583": "Filtra eventi in cronologia e timeline.",
  "markdownhelper.js133555219": "Trova evento nella timeline",
  "markdownhelper.js499018470": "Una freccia ti indica la posizione dell'evento nella linea temporale.",
  "markdownhelper.js743956038": "La posizione dell'evento viene mostrata su una mappa.",
  "markdownhelper.js813604085": "Si aprirà una nuova scheda del browser con l'articolo.",
  "addstorydescriptor.js556883219": "hiStories incorporati",
  "addstorydescriptor.js110462036": "nuova hiStory incorporata",
  "bookingrights.js50879320": "Accedi per condividere hiStories con altri.",
  "admincomponent.js149123880": "Chiudi",
  "loginbutton.js370395105": "accedi / registrati gratuitamente",
  "mediaitemlistbutton.js846631503": "Elenco di tutti i media",
  "registerbutton.js180629076": "registrati gratuitamente",
  "hintdialog.js158836303": "Chiudi",
  "barexpansion.js805637512": "normale",
  "barexpansion.js271938727": "25 volte",
  "barexpansion.js680468558": "50 volte",
  "barexpansion.js321153821": "75 volte",
  "barexpansion.js970143224": "100 volte",
  "barposition.js477225547": "in fondo",
  "barposition.js21166688": "normale",
  "barposition.js400652493": "in cima",
  "bookingrightsadministration.js501993017": "Autorizza un altro utente",
  "streetview.js459438593": "Acconsento alla visualizzazione di contenuti di Google Maps. Questo potrebbe comportare la trasmissione di dati personali a terze parti.",
  "displaystorydetails.js463453345": "Eventi. Clicca su una voce per dettagli o sull'icona dell'orologio per trovarla nella timeline.",
  "dataset.newLine": "nuova riga",
  "dataset.fromCSV": "da csv",
  "markdowneditfield.name": "Nome",
  "markdowneditfield.birth": "Data di nascita",
  "addmarkdownbutton.53645": "Questo è un titolo Markdown",
  "timeblock.3457335": "Inserisci o la durata o la fine, l'altro valore sarà calcolato di conseguenza. Se non specificato, la fine sarà fino al tempo corrente.",
  "innerevents.34353463": "Per ora è disponibile solo l'editor JSON per l'inserimento.",
  "bargroup.54345345": "Eventi dello stesso gruppo sono mostrati in un blocco etichettato nella timeline.<br/>Le etichette dei gruppi sono case-sensitive, cioè fanno distinzione tra maiuscole e minuscole.",
  "cookieconsent.0": "Prima di continuare",
  "cookieconsent.1": "Cookie tecnicamente necessari",
  "cookieconsent.2": () => <>I cookie operativi non possono essere disattivati poiché altrimenti il nostro sito non funzionerebbe.<br/>Usiamo ad esempio cookie di sessione per salvare le tue attuali scelte di hiStories.<br/>Inoltre, contenuti generati dagli utenti possono includere pagine di YouTube e Google Maps.<br/>Questo potrebbe far sì che i produttori citati impostino cookie.<br/>Ti chiederemo di nuovo prima di caricare tali contenuti se lo desideri.<br/>La tua scelta verrà memorizzata nel local storage del browser.<br/><br/></>,
  "cookieconsent.3": "Cookie di analisi",
  "cookieconsent.4": "Utilizziamo Google Analytics per creare statistiche. Non vengono elaborati dati personali. Gli indirizzi IP sono memorizzati in modo anonimo (le ultime cifre sono sostituite da una X).",
  "cookieconsent.5": "Puoi revocare le impostazioni di cookie e storage locale in qualsiasi momento scegliendo 'Reimposta impostazioni cookie' nel menu.",
  "enlargeToSelection": "ingrandisci alla selezione",
  "fixDuration": "Fissa la durata",
  "endMeasurement": "Termina la misurazione",
  "secondsFromNow": "secondi da ora",
  "minutesFromNow": "minuti da ora",
  "hoursFromNow": "ore da ora",
  "daysFromNow": "giorni da ora",
  "chronology": "Cronologia",


 "languageinput.js593099979": "Lingua",
"fontSettings.js878339034": "Carattere",
"fontSettings.js887101724": "-volte",
"fontSettings.js728758094": "Imposta l'altezza dell'etichetta come un multiplo dell'altezza normale.",
"fontSettings.quarter": "1/4",
"fontSettings.half": "1/2",
"fontSettings.threequarters": "3/4",
"fontSettings.full": "normale",
"fontSettings.oneandahalf": "1,5 volte",
"fontSettings.two": "doppio",
"fontSettings.transparency": "Opacità",
"fontSettings.height": "Altezza",
"fontSettings.25percent": "25%",
"fontSettings.50percent": "50%",
"fontSettings.75percent": "75%",
"fontSettings.100percent": "100%",
"fontSettings.bold": "grassetto",
"fontSettings.italic": "corsivo",
"deleteProfile": "Elimina il mio profilo",
"deleteProfileContent": () => <>Eliminare il tuo profilo non elimina le tue hiStories.<br/>Queste possono rimanere visibili ad altri utenti sotto un profilo anonimo.<br/>Se desideri che le tue hiStories non siano più accessibili agli altri, cancellale prima di eliminare il tuo profilo.<br/><br/>Vuoi eliminare irrevocabilmente il tuo profilo?</>,
"menu.mapForTimeInterval": "Mappa riassuntiva dell'intervallo di tempo",
"relation.js272862577": "Linee di connessione",
"relation.js904830442": "Linee di connessione",
"connection.target": "Obiettivo della connessione",
"connection.name": "Designazione",
"connection.color": "Colore",
"connection.width": "Larghezza",
"connection.textpos": "Posizione del testo",
"connection.startpos": "Posizione iniziale",
"connection.endpos": "Posizione finale",
"schoolclass6": "6",
"schoolclass7": "7",
"schoolclass8": "8",
"schoolclass9": "9",
"schoolclass10": "10",
"schoolclass11": "+",


  "curriculum.title": "storie divise per classe",
  "curriculum.text": "Qui trovi una selezione di storie divise per classe. Poiché ogni regione ha il proprio piano di studi, la suddivisione è solo approssimativa.",
  "welcome.about": "Su histomania",
  "pricingbutton.text": "Prezzi",
  "pricing.free": "Gratuito",
  "pricing.school": "Scuola",
  "pricing.plus": "Plus",
  "pricing.maturity": "La durata della licenza è di 12 mesi e non è automaticamente prorogabile, salvo accordo separato.",
  "pricing.freebody": () => <><h2 style={{display: 'inline-block'}}>0,<span style={{verticalAlign: 'super', fontSize: '0.75em'}}>00</span> €</h2><br/>versione base gratuita per uso privato, commerciale o educativo.<br/><br/><hr/><br/><ul><li>permanente gratuito</li><li>Integrazione delle timeline nei propri siti web</li><li>Creazione di proprie storie</li></ul></>,
  "pricing.schoolbody": () => <span>a partire da <h2 style={{display: 'inline-block'}}>2,<span style={{verticalAlign: 'super', fontSize: '0.75em'}}>40</span> €</h2> / studente / anno<br/>Per iscrizioni tramite VIDIS, edulog, con indirizzi email scolastici o configurazioni individuali.<br/><br/>a partire da un insegnante e 10 studenti<hr/><br/><ul><li>Piena visibilità dei contenuti a pagamento</li><li>Creazione di contenuti privati</li><li>Limitato a scuola.histomania.com</li><li>verificato</li><li>senza pubblicità</li></ul></span>,
  "pricing.plusbody": () => <span>a partire da <h2 style={{display: 'inline-block'}}>29,<span style={{verticalAlign: 'super', fontSize: '0.75em'}}>90</span> €</h2> / utente / anno<br/>Per utenti privati e commerciali.<br/><br/><hr/><br/><ul><li>Piena visibilità dei contenuti a pagamento</li><li>Creazione di contenuti privati</li><li>Possibilità di esportazione</li><li>senza pubblicità</li></ul></span>,
  "pricing.configureAndOrder": "Configura e ordina",
  "licenses": "Licenze",
  "licenseCard.determineUsers": "Qui puoi determinare quali utenti possono utilizzare la tua licenza al momento della registrazione.",
  "licenseCard.recognizeUsers": "In base a quali caratteristiche è possibile riconoscere gli utenti che possono registrarsi con questa licenza?",
  "licenseCard.schoolVidisEduca": "Scuola (solo VIDIS o EDUCA)",
  "licenseCard.stateVidisEduca": "Regione (solo VIDIS o EDUCA)",
  "licenseCard.mailDomain": "Dominio email",
  "licenseCard.username": "Nome utente",
  "licenseCard.schoolLicense": "Licenza scolastica",
  "licenseCard.plusLicense": "Licenza Plus",
  "licenseCard.purpose": "Scopo dell'uso",


"licenseCard.orderDate": "Data dell'ordine",
"licenseCard.validity": "Validità",
"licenseCard.loginType": "Accessi insegnante",
"licenseCard.userLoginType": "Accessi utente",
"licenseCard.studentLogins": "Accessi studenti",
"licenseCard.status": "Stato",
"licenseCard.paid": "pagato",
"licenseCard.paymentPending": "pagamento in sospeso",
"licenseCard.billingAddress": "Indirizzo di fatturazione",
"licenseCard.showAddress": "Mostra indirizzo di fatturazione",
"menu.onFacebook": "su Facebook",
"menu.onTwitter": "su Twitter",
"menu.onWhatsApp": "su WhatsApp",
"menu.embedStory": "Incorpora storia",
"menu.qrCode": "Codice QR",
"listMenu.nestedListFacebook": "Una Timeline su",
"listMenu.nestedListTwitter": "Una Timeline su",
"listMenu.nestedListWhatsApp": "Una Timeline su",
"listMenu.nestedListMailSubject": "Timeline su",
"embed.title": "Incorpora",
"embed.showHeader": "Mostra intestazione",
"embed.showArticle": "Mostra articolo",
"embed.darkBackground": "Sfondo della Timeline scuro",
"embed.showBgImage": "Mostra immagine di sfondo nella Timeline",
"embed.showFab": "Pulsante di azione flottante visibile",
"embed.htmlSnippet": "Frammento HTML",
"sourceReference.uneImgAlt": "Un'immagine di ",
"import.title": "Importazione",
"import.warnTitle": "Avviso",
"import.csvTextLabel": "Testo CSV",
"import.description": ()=><>Le righe che non sono state importate rimangono nel campo di testo dopo l'importazione<br/>Una descrizione del formato richiesto si trova</>,
"import.here": "qui",
"qrCodePage.title": "Codice QR",

"qrCodePage.instruction": "Puoi salvare e stampare questo codice QR.",
"qrCodePage.downloadButton": "Download in formato svg",
"histomaniamain.sessionover": "La tua sessione è scaduta.",
"histomaniamain.checkconnection": "Per favore controlla la connessione internet.",
"histomaniamain.isLoading": "caricamento...",
"histomaniamain.pleasewait": "per favore aspetta",
"histomaniamain.whatif": "e se...",
"histomaniamain.pressHere": "Premi qui per 2 secondi per creare un nuovo evento",
"histomaniamain.chooseStoryForNewEvent": "Scegli la storia per il nuovo evento.",
"export.title": "Esporta",
"export.lockWarningText": "La funzionalità di esportazione non è disponibile. Scopri di più sull'accesso completo",
"pricing.subdomain": "Hai bisogno di un sottodominio su histomania.com o hai problemi di finanziamento nella tua scuola?",
"pricing.testAgreement": "Desideri un accordo di prova personalizzato?",
"pricing.contactUs": "Qualunque esigenza individuale tu abbia, contattaci",
"pricing.vatIncluded": "I nostri prezzi includono l'IVA.",
"pricing.findSolution": "Troveremo una soluzione comune.",
"priceLegendSchool.pricesPerYearPerUser": "Prezzi per anno e per utente",
"priceLegendSchool.teachers": "Insegnanti",
"priceLegendSchool.from5Teachers": "da 5 insegnanti",
"priceLegendSchool.from10Teachers": "da 10 insegnanti",
"priceLegendSchool.students": "Studenti",
"priceLegendSchool.from100Students": "da 100 studenti",
"priceLegendSchool.from300Students": "da 300 studenti",
"priceLegendPlus.pricesPerYearPerUser": "Prezzi per anno e per utente",
"priceLegendPlus.oneToFourUsers": "1-4 utenti",
"priceLegendPlus.fiveToTwentyUsers": "5-20 utenti",
"priceLegendPlus.twentyOneToNinetyNineUsers": "21-99 utenti",
"priceLegendPlus.hundredOrMoreUsers": "da 100 utenti",
"priceCalculator.errorOccurred": "Si è verificato un errore durante l'invio dei dati.",
"priceCalculator.contactSupport": "Per favore contatta direttamente via email a bestellung@histomania.com.",
"priceCalculator.licenseConfig": "Configurazione licenza per",
"priceCalculator.schools": "Scuole e enti",
"priceCalculator.privateUsers": "utenti privati e aziendali",
"priceCalculator.howManyUsers": "Quanti diversi utenti lavoreranno con histomania ogni anno al massimo?",
"priceCalculator.numTeachers": "Numero di insegnanti",
"priceCalculator.numUsers": "Numero di utenti",
"priceCalculator.atLeastOne": "Si calcola almeno un",
"priceCalculator.calculated": "calcolato.",
"priceCalculator.numStudents": "Numero di studenti",
"priceCalculator.atLeastTenStudents": "Si calcolano almeno 10 studenti.",
"priceCalculator.validFrom": "Valido da",
"priceCalculator.totalPrice": "Prezzo totale:",
"priceCalculator.perYear": "all'anno",
"priceCalculator.billingAddress": "Indirizzo di fatturazione",
"priceCalculator.purchaseInfo": "Per effettuare un ordine è necessario avere un account histomania ed essere registrato.",
"priceCalculator.profileManagement": "Dopo l'ordine, puoi visualizzare lo stato della tua licenza e configurare gli accessi sotto la voce di menu 'Gestione profilo | Licenze'.",
"priceCalculator.defineUsers": "Qui definisci anche gli utenti singoli o i gruppi di utenti che possono accedere con questa licenza.",
"priceCalculator.includesTax": "I nostri prezzi includono il 19% di IVA tedesca.",
"priceCalculator.foreignOrders": "Per gli ordini dall'estero potrebbero essere applicate imposte nel paese ricevente, la cui pagamento spetta al cliente.",
"priceCalculator.chooseFutureDate": "Per favore scegli una data valida, attuale o futura.",
"orderError.orderErrorOccurred": "Si è verificato un errore nel tuo ordine.",
"orderError.contactSupport": "Si prega di contattare direttamente info@histomania.com.",
"checkout.pleasePayAmount": "Per favore paga l'importo di",

"checkout.within14Days": "entro 14 giorni sul seguente conto:",
"checkout.peterRankSoftware": "Peter Rank Software",
"checkout.accountNumber": "Numero di conto: DE55 7019 0000 0007 5237 42",
"checkout.bankCode": "Codice bancario: GENO DEF1 M01 (Münchner Bank e.G.)",
"checkout.paymentReference": "Causale:",
"checkout.payConveniently": "Oppure paga rapidamente e comodamente con carta di credito, Klarna, Bancontact o bonifico eps:",
"checkout.continueToStripe": "Continua al fornitore di servizi di pagamento Stripe",
"checkout.specifyReference": "Si prega di specificare sempre la causale",
"checkout.findNumberProfile": "Troverai questo numero anche sotto il menù 'Gestione profilo | Licenze' e sulla tua fattura.",
"checkout.configureLicenses": "Lì puoi anche configurare le tue licenze.",
"checkout.toLicenseAdmin": "Alla gestione delle licenze",
"checkout.noNeedToWait": "Non devi aspettare che l'accredito del pagamento sia stato effettuato.",
"checkout.fullAccess": "Ti abbiamo già concesso l'accesso completo per i prossimi 14 giorni.",
"checkout.reviewOrder": "Esamineremo il tuo ordine e ti invieremo una fattura con le informazioni di pagamento all'indirizzo e-mail fornito.",
"checkout.noNeedToWaitInvoice": "Non devi aspettare di ricevere la fattura e l'effettuazione del pagamento.",
"checkout.toLicenseAdminLower": "alla gestione delle licenze",
"addressForm.schoolEmptyError": "Scuola/ente non può essere vuoto.",
"addressForm.prenameEmptyError": "Il nome non può essere vuoto.",
"addressForm.surnameEmptyError": "Il cognome non può essere vuoto.",
"addressForm.streetEmptyError": "La strada non può essere vuota.",
"addressForm.streetNumberEmptyError": "Il numero civico non può essere vuoto.",
"addressForm.zipCodeInvalidError": "Il CAP deve essere di 5 cifre.",
"addressForm.townEmptyError": "La città non può essere vuota.",
"addressForm.countryEmptyError": "Il paese non può essere vuoto.",
"addressForm.emailInvalidError": "Indirizzo email non valido.",
"addressForm.countryLabel": "Paese",
"addressForm.germany": "Germania",
"addressForm.austria": "Austria",
"addressForm.switzerland": "Svizzera",
"addressForm.taxNumberLabel": "Numero fiscale",
"addressForm.taxNumberHelper": "Se la tua organizzazione possiede un numero fiscale",
"addressForm.schoolOrOrgLabel": "Scuola/ente",
"addressForm.companyLabel": "Azienda",
"addressForm.prenameLabel": "Nome",
"addressForm.surnameLabel": "Cognome",
"addressForm.streetLabel": "Strada",
"addressForm.streetNumberLabel": "Numero civico",
"addressForm.zipCodeLabel": "CAP",
"addressForm.townLabel": "Città",
"addressForm.emailLabel": "Indirizzo email",
"zoomableImage.bild": "Immagine: ",
"editButton.bearbeiten": "Modifica",
"pregnancyDialog.yourPregnancyCalendar": "Il tuo calendario della gravidanza",
"pregnancyDialog.enterDate": "Inserisci la data dell'ultima mestruazione o la data della fecondazione per vedere il tuo calendario di gravidanza personalizzato.",
"pregnancyDialog.firstDayLastPeriod": "Primo giorno dell'ultima mestruazione",
"pregnancyDialog.dayOfFertilization": "Giorno della fecondazione",
"pregnancyDialog.from": "da",
"pregnancyDialog.loading": "Caricamento...",
"pregnancyDialog.whatIf": "cosa sarebbe, se...",
"pregnancyDialog.firstDayLastPeriodTask": "Primo giorno dell'ultima mestruazione",
"despotbirthday.title": "Confrontati con i despoti",
"despotbirthday.prompt": "Cosa sarebbe successo se i despoti del 20° secolo fossero nati con te?",
"despotbirthday.chooseBirthday": "Inserisci la tua data di nascita e puoi vedere cosa altri tiranni hanno già realizzato alla tua età.",
"despotbirthday.label": "Quando è il tuo compleanno?",
"despotbirthday.loading": "Caricamento...",
"usersPerDay.newUsers": "Nuovi utenti al giorno",
"usersPerDay.day": "Giorno",
"usersPerDay.count": "Quantità",
"users.loadingError": "Errore nel caricamento dei dettagli dell'utente",
"users.privilegesError": "Errore nell'assegnazione dei privilegi amministrativi",
"users.countText": "Utenti, di cui",
"users.validatedText": "convalidati",
"users.newsletterSubscribersText": "Iscritti alla newsletter, di cui",
"users.headingUsers": "Utenti",
"users.emailHeading": "Email",
"users.forenameHeading": "Nome",
"users.surnameHeading": "Cognome",
"users.newsletterHeading": "Newsletter",
"users.validatedHeading": "Convalidato",
"users.numHistories": "Numero di hiStories",
"userDetails.hiStories": "hiStories",
"userDetails.id": "ID",
"userDetails.name": "Nome",

"userDetails.public": "pubblico",
"userDetails.adminPrivileged": "amministratore autorizzato",
"userDetails.events": "eventi",
"userDetails.lastEventChange": "ultima modifica evento",
"userDetails.lastStoryChange": "ultima modifica storia",
"topVisited.topPages": "Pagine più visitate",
"topVisited.visits": "visite",
"topVisited.page": "pagina",
"topVisited.creator": "creatore",
"topVisited.events": "eventi",
"topVisited.creationTime": "ora di creazione",
"topTaskCnt.title": "Pagine con molti eventi",
"topTaskCnt.visits": "visite",
"topTaskCnt.page": "pagina",
"topTaskCnt.creator": "creatore",
"topTaskCnt.events": "eventi",
"topTaskCnt.creationTime": "ora di creazione",
"search.from": "da",
"search.to": "a",
"titlelist.ago": (years)=>"fa "+years+" anni",
"deleteDialog.title": "Eliminare davvero?",
"deleteDialog.confirmationText": "Vuoi davvero eliminare la hiStory?",
"dummyUserHint.warningText": "Creata a scopo di test, verrà eliminata dopo un po' di tempo.",
"dummyUserHint.notPublished": "Non puoi pubblicare questa hiStory.",
"dummyUserHint.notAddedToAccount": "La hiStory non verrà aggiunta al tuo account quando accedi.",
"dummyUserHint.logonHere": "Accedi",
"dummyUserHint.here": "qui",
"bookingRightsAdmin.notAvailable": "L'assegnazione delle autorizzazioni non è disponibile.",
"bookingRightsAdmin.publiclyAccessible": "La nuova hiStory è accessibile pubblicamente.",
"bookingRightsAdmin.learnMore": "Scopri di più sull'abilitazione delle autorizzazioni.",
"userrightsdialog.userneverloggedin": "L'utente non si è mai connesso",
"displayStoryDetails.publishPrompt": "Pubblica questa hiStory affinché tutti possano trovarla e vederla:",
"displayStoryDetails.publishNow": "Pubblica ora",
"displayStoryDetails.storyProtected": "Questa hiStory è protetta.",
"displayStoryDetails.textScrambled": "Le lettere nei testi sono state mescolate.",
"displayStoryDetails.learnMoreFullAccess": "Scopri di più sull'accesso completo.",
"displayStoryDetails.map": "mappa",
"displayStoryDetails.mindmap": "mappa mentale",
"displayStoryDetails.position": "posizione",
"printdialog.barSizeLabel": "Altezza della barra [px]",
"printdialog.widthLabel": "Larghezza [px]",
"printdialog.heightLabel": "Altezza [px]",
"printdialog.altText": "Anteprima",
"printdialog.from": "da",
"printdialog.to": "a",
"print.legendSource": "Fonte",
"menu.active": "attivo",
"youtube.consentLabel": "Acconsento che mi vengano mostrati contenuti di YouTube. Ciò può comportare la trasmissione di dati personali a terzi.",
"mediaFile.mapTooltip": "Mostra mappa",
"mediaFile.videoTooltip": "Mostra video",
"mediaFile.streetViewTooltip": "Mostra StreetView",
"mediaFile.quizTooltip": "Mostra quiz",
"googleMapContent.undurchsichtig": "opaco",
"googleMapContent.durchsichtig": "trasparente",
"googleMapContent.transparenz": "trasparenza",
"googleMapContent.gitter": "griglia",
"googleMapContent.kontrollpunkte": "punti di controllo",
"googleMapContent.overlay": "sovrapposizione",
"quizQuestion.question": "domanda",
"quizQuestion.addAnswer": "Aggiungi risposta",
"quizQuestion.addExplanation": "Clicca qui per inserire una spiegazione",
"quizEditor.addQuestion": "Aggiungi domanda",
"quizAnswer.antwort": "Risposta ",

  "quiz.landingHeaderText": (nrOfQuestions)=> nrOfQuestions+" domande",
  "quiz.question": "Domanda",
  "quiz.startQuizBtn": "Avvia il quiz",
  "quiz.resultFilterAll": "Tutti",
  "quiz.resultFilterCorrect": "corretto",
  "quiz.resultFilterIncorrect": "errato",
  "quiz.nextQuestionBtn": "Continua",
  "quiz.resultPageHeaderText": (correctlength, questionslength)=>"Hai risposto correttamente a "+correctlength+" di "+questionslength+" domande.",
  "quiz.resultPagePoint": (correctPoints, totalPoints)=>"Ottieni "+correctPoints+" su "+totalPoints+" punti.",
  "quiz.singleSelectionTagText": "Selezione singola",
  "quiz.multipleSelectionTagText": "Selezione multipla",
  "quiz.pickNumberOfSelection": (numberOfSelection) => "Seleziona "+numberOfSelection+" risposte",
  "quiz.pickNumberOfSelectionSingle": "Seleziona una risposta",
  "quiz.auswertung": "Valutazione",
  "instantFeedback.correctMessage": "Corretto! Clicca su Continua per procedere.",
  "instantFeedback.incorrectMessage": "Risposta sbagliata. Per favore, riprova.",
  "markdowneditfield.videoEdit": "Modifica video",
  "markdowneditfield.mapEdit": "Modifica mappa",
  "markdowneditfield.insertTable": "Inserisci tabella",
  "markdowneditfield.hr": "Inserisci linea orizzontale",
  "markdowneditfield.linkWith": "Collega il testo selezionato con hiStory, evento o filtro",
  "markdowneditfield.convertToBullet": "Converti il testo selezionato in elenco puntato",
  "markdowneditfield.convertToQuote": "Converti il testo selezionato in citazione",
  "markdowneditfield.preview": "Anteprima",
  "markdowneditfield.mdFormatting": "(Formattazione Markdown possibile)",
  "textField.ariaSearch": "Cerca",
  "filterTimeline.tip": "Suggerimento: Clicca prima l'occhio barrato su 'Tutti' per filtrare via tutti gli eventi e poi aggiungi gradualmente eventi.",
  "filterTimeline.showIds": "Mostra ID non filtrati",
  "maintenanceeventlisttable.andauernd": "in corso",
  "maintenanceeventlisttable.clickToEdit": "Clicca sulla cella della tabella desiderata per modificare o '...' per i dettagli.",
  "maintenanceeventlisttable.autoSaveInfo": "Ogni modifica viene salvata immediatamente quando lasci la cella della tabella.",
  "addPanoramaButton.title": "Porta di Brandeburgo",
  "imageButton.title": "La mia immagine",
  "chooseMarkdownDialog.markdown": "Markdown",
  "chooseMarkdownDialog.leftMargin": "margine sinistro",
  "chooseMarkdownDialog.rightMargin": "margine destro",
  "chooseMapDialog.selectedPosition": "posizione scelta",
  "chooseMapDialog.marker": "marker",
  "chooseMapDialog.arrow": "freccia",
  "chooseMapDialog.overlay": "overlay",
  "chooseMapDialog.newMarker": "nuovo marker",
  "chooseMapDialog.newArrow": "nuova freccia",
  "chooseMapDialog.newOverlay": "nuovo overlay",
  "chooseMapDialog.arrowInstruction": "Clicca il percorso della freccia e alla fine su",
  "chooseMapDialog.overlayStep1": "Passo 1:",
  "chooseMapDialog.uploadImage": "Carica la tua immagine e definisci l'area",
  "chooseMapDialog.pointEndInstruction": "Rimuovi i punti con [Shift + Clic].",
  "chooseInfoBlock.infoblock": "Blocco info",
  "chooseInfoBlock.backgroundColor": "Colore sfondo",
  "chooseInfoBlock.leftMargin": "margine sinistro",
  "chooseInfoBlock.rightMargin": "margine destro",
  "chooseMapDialog.click": "Clicca il percorso della freccia e alla fine su",
  "chooseMapDialog.or": "o",
  "chooseMapDialog.takeover": "adotta",
  "bookmarks.jsSaveBookmark": "salva come segnalibro",
  "bookmarkItem.js.foundStories": (size)=>"(Riepilogo di "+size+" storie)",
  "bookmarkdialog.name": "Nome",
  "editbookingdetails.reallydelete": "Eliminare davvero?",
  "editbookingdetails.newEvent": "Nuovo evento",
  "editbookingdetails.event": "Evento",
  "dataset.csvimport": "Importa CSV",
  "dataset.table": "Tabella",
  "dataset.json": "JSON",
  "connections.newConnection": "nuova connessione",
"menu.france": "Francese",
"menu.italy": "Italiano",
  "exportToExcel": "Esportazione dei dati in MS Excel",
  "exportToJSON": "Esportazione dei dati in JSON",
  "nomoretwitter": () => <>
    Finora hai effettuato l'accesso qui tramite il tuo account Twitter/X.<br/>
    Richiedi semplicemente una nuova password tramite "Password dimenticata" all'indirizzo email del tuo account Twitter/X e accedi direttamente con noi.
  </>,
  "langNotAvailable": "Siamo spiacenti, la lingua desiderata non è disponibile per",
  "and": "e",
  "menu": "Menu",
}

export default res