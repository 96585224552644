import React, {useState} from "react";
import SchoolIcon from "@material-ui/icons/School";
import PlusIcon from "@material-ui/icons/Person";
import FreeIcon from "@material-ui/icons/LocalOffer";
import Grid from "@material-ui/core/Grid";
import i18n from "../i18n/i18n";
import PricingItem from "./pricingitem";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PriceCalculator from "./pricecalculator";
import Typography from "@material-ui/core/Typography";
import BackButton from "../ui-components/buttons/text/backbutton";

const Pricing = ({onLogin, onBack, userLoggedIn, loginUserIsDummy, onCheckout}) => {
    const [showCalculator, setShowCalculator] = useState(false);
    const [calculatorType, setCalculatorType] = useState("");

    return <>
        {!showCalculator && <>
            <Grid container spacing={3}>
                <PricingItem icon={<FreeIcon/>} title={i18n("pricing.free")} body={i18n("pricing.freebody")()}
                         actionText={i18n("loginbutton.js370395105")} actionIcon={<AccountCircleIcon/>} onAction={onLogin} actionHidden={userLoggedIn && !loginUserIsDummy}/>

                <PricingItem icon={<SchoolIcon/>} title={i18n("pricing.school")} body={i18n("pricing.schoolbody")()}
                         actionText={i18n("pricing.configureAndOrder")} actionIcon={<ShoppingCartIcon/>} onAction={()=>{setShowCalculator(true); setCalculatorType("school");}}/>

                <PricingItem icon={<PlusIcon/>} title={i18n("pricing.plus")} body={i18n("pricing.plusbody")()}
                         actionText={i18n("pricing.configureAndOrder")} actionIcon={<ShoppingCartIcon/>} onAction={()=>{setShowCalculator(true); setCalculatorType("plus");}}/>
            </Grid>
            <Typography variant="caption" style={{marginTop: 40, marginBottom: 40}}>
                {i18n('pricing.maturity')}
                <br/>
                <br/>
                {i18n('pricing.subdomain')}
                <br/>
                {i18n('pricing.testAgreement')}<br/>
                {i18n('pricing.contactUs')} <a
                href={"mailto:info@histomania.com"}>info@histomania.com</a>. {i18n('pricing.findSolution')}
                <br/>
                <br/>
                {i18n('pricing.vatIncluded')}
            </Typography></>}
        {showCalculator && <PriceCalculator calculatorType={calculatorType} onBack={()=>setShowCalculator(false)} userLoggedIn={userLoggedIn} loginUserIsDummy={loginUserIsDummy} onLogin={onLogin} onCheckout={onCheckout}/>}
        {!showCalculator && <BackButton onClick={onBack}/>}
    </>
}


    export default Pricing;
