import React from "react";
import {Facebook, Instagram, Twitter, YouTube} from "@material-ui/icons";
import i18n from "../i18n/i18n";

const Footer = () => (
        <div style={{display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between", color: "lightgray"}}>
            <div style={{width: 200, marginBottom: 20}}><h4>{i18n("contact")}</h4>Peter Rank
                    Software<br/>Hermann-Stockmann-Straße 55B<br/>85221 Dachau<br/>
            </div>
            <div style={{width: 200, marginBottom: 20}}>info@histomania.com <br/>{i18n("phone")}: (+49)
                    163 / 41 83 107 <br/>
            </div>
          {!window.isSchoolDomain && <div style={{display: "flex", width: 200, justifyContent: "center"}}>
                    <a href={"https://x.com/Histum"} target={"_blank"} rel={"noopener noreferrer"} aria-label={"X/Twitter"}><Twitter color={"primary"} fontSize="large"/></a>
                    <a href={"https://www.facebook.com/Histum/"} target={"_blank"} rel={"noopener noreferrer"} aria-label={"Facebook"}><Facebook color={"primary"} fontSize="large"/></a>
                    <a href={"https://www.instagram.com/histum/"} target={"_blank"} rel={"noopener noreferrer"} aria-label={"Instagram"}><Instagram color={"primary"} fontSize="large"/></a>
                    <a href={"https://www.youtube.com/channel/UCSt-HNeX9ZGQY7B66P33gAg"} target={"_blank"} rel={"noopener noreferrer"} aria-label={"YouTube"}><YouTube color={"primary"} fontSize="large"/></a>
            </div>}
    </div>
);

export default Footer;
