import React, { useState, useEffect, useRef } from "react";
import Item from './item';
import MoreItem from './moreitem';
import { LCal, LCalFormatter, LCalHelper } from "react-canvas-timeline";
import LinearProgress from '@material-ui/core/LinearProgress';
import 'whatwg-fetch';
import './titlelist.scss';
import i18n from "../../i18n/i18n";
import BaseConfig from "../../config/config";
import Config from "../../config/config";

const getParameterByName = (name) => {
    let url = window.location.href;
    name = name.replace(/[[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
};

const TitleList = ({ url, title, id, titleMini, onClick, isBirthday }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [moreData, setMoreData] = useState([]);
    const divRef = useRef(null);
    const [isDivVisible, setIsDivVisible] = useState(false);

    const loadContent = (dataLoaded, startIndex = 0, offset = 6) => {
        setIsLoading(true);

        const day = getParameterByName("day");
        const month = getParameterByName("month");
        const year = getParameterByName("year");

        const requestUrl = `${BaseConfig.getBaseURL()}landingpage/${url}`;

        const headers = new Headers({
            "Content-Type": "application/json"
        });

        const request = new Request(requestUrl, {
            method: 'post',
            headers: headers,
            body: JSON.stringify({ startIndex, offset, day, month, year })
        });

        fetch(request)
            .then((response) => response.json())
            .then((json) => {
                dataLoaded(json);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                console.log("Es ist ein Fehler beim Aufruf von " + requestUrl + " aufgetreten:");
            });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                setIsDivVisible(entry.isIntersecting);
            },
            { threshold: 0.1 }
        );

        if (divRef.current) {
            observer.observe(divRef.current);
        }

        return () => {
            if (divRef.current) {
                observer.unobserve(divRef.current);
            }
        };
    }, []);

    useEffect(() => {
        // Load content only if the div is visible and data has not been loaded yet
        if (isDivVisible && data.length === 0) {
            loadContent((json) => setData(json)); // Load data only if it's not already loaded
        }
    }, [isDivVisible, data.length, url]); // Monitor visibility and length of data

    const getBackDrop = (item) => {
        let backDrop = Config.getBaseURL() + "images/default.png";
        if (item.wikiimg) {
            backDrop = Config.getBaseURL() + 'image/getwikiimage.php?name=' + encodeURI(item.wikiimg);
        } else if (item.imgSrc) {
            backDrop = Config.getBaseURL() + 'images/resourceimages/' + encodeURI(item.imgSrc);
        }
        return backDrop;
    };

    const getPreTitle = (item) => {
        const d = new LCal().initYMDHM(item.year, item.month, item.day);
        const now = new LCal().initNow();

        const day = getParameterByName("day");
        const month = getParameterByName("month");
        const year = getParameterByName("year");

        now.initYMDHM(year ? Number(year) : now.getYear(), month ? Number(month) : now.getMonth(), day ? Number(day) : now.getDay());

        const years = now.getDistanceInYMDHM(d)[0];

        return item.year && item.month && item.day ? (
            <div>{LCalFormatter.formatDate(d) + " ("+i18n("titlelist.ago")(years)+")"}</div>
        ) : null;
    };

    var titles = [];
    if(Array.isArray(data)) {
        titles = data?.map((item, i) => {
            if (i < 5) {
                const backDrop = getBackDrop(item);
                const preTitle = getPreTitle(item);

                return (
                    <Item key={item.id}
                          id={item.id}
                          preTitle={preTitle}
                          title={item.name}
                          overview={item.description}
                          backdrop={backDrop}
                          imgLicense={item.imglicense}
                          wikiImg={item.wikiimg}
                          titleMini={titleMini}
                          locked={item.locked}
                          language={item.language}
                          bgColor={"#F4F4F4"}
                          onClick={() => onClick && onClick(item.id, item.hyperlink)}
                    />
                );
            }
            return null;
        });
    }

    var moreTitles = [];
    if(Array.isArray(moreTitles)) {
        moreTitles = moreData?.map((item) => {
            const backDrop = getBackDrop(item);
            const preTitle = getPreTitle(item);

            return (
                <MoreItem key={item.id}
                          id={item.id}
                          preTitle={preTitle}
                          title={item.name}
                          overview={item.description}
                          backdrop={backDrop}
                          imgLicense={item.imglicense}
                          wikiImg={item.wikiimg}
                          locked={item.locked}
                          language={item.language}
                          onClick={() => onClick && onClick(item.id, item.hyperlink)}
                />
            );
        });
    }

    return (
        <div ref={divRef}>
            <div className="TitleList">
                <div className="Title">
                    <h2 id={id} style={{ fontSize: 18 }}>{title}</h2>
                    <div className="titles-wrapper">
                        {titles}
                    </div>
                    {moreData.length === 0 && !isLoading && data.length > 5 && (
                        <div className="more" onClick={() => loadContent((json) => setMoreData(json), 5, 100)}>
                            {i18n("more")}
                        </div>
                    )}
                    {moreData.length > 0 && !isLoading && (
                        <div className="more" onClick={() => setMoreData([])}>
                            {i18n("less")}
                        </div>
                    )}
                    {isLoading && <LinearProgress />}
                    <div className="moretitles-wrapper">
                        {moreTitles}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TitleList;
