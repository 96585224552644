import React from 'react';

import Config from "../config/config";

const Advertising = ({id, isResource}) => (
   !window.isSchoolDomain && <div style={{display: "flex", flexDirection: "column"}}>
    <span style={{color: 'gray', margin: 10}}>Anzeige</span>
    <div style={{overflow: 'hidden'}}>
      <iframe style={{
        marginTop: -85,
        marginLeft: -6,
        marginRight: 4,
        marginBottom: -10
      }} src={Config.getAdvertisingURL() + "?id=" + id + "&isResource="
      + isResource} title={"Advertising"} height={340} frameBorder={0}
              scrolling="no"></iframe>
    </div>
  </div>
)

export default Advertising;
