import React from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from './textfield';
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import SearchIcon from '@material-ui/icons/Search';
import FilterIcon from "../../images/icons/filter";
import i18n from "../../i18n/i18n";

const getLabel = (option) => {
  return (option.name && option.name !== '' ? option.name : option.secname) || "";
}


const CustomPopper = (props) => {
  //const classes = useStyles();
  return <Popper {...props} style={{width: 'calc(100vW - 20px)', maxWidth: 800}} placement="bottom">
    <Paper elevation={10} {...props} style={{width: 'calc(100vW - 20px)', maxWidth: 800}}>
    </Paper>
  </Popper>
};

const blur = () => {
  document.activeElement && document.activeElement.blur();
}

const SearchField = ({model, onExtendedSearch, onExtendedFilter, onHighlight, width, onInputChange, inputValue}) => {
  const [options, setOptions] = React.useState([]);
  const startOptions = [
          {action: 'search', name: i18n("searchfield.js825023426"), type: 'search'},
          {action: 'nearby', name: i18n("searchfield.js18900516"), type: 'search'},
          {action: 'positiontime', name: i18n("searchfield.js363834300"), type: 'search'},
          {action: 'myhistories', name: i18n("searchfield.js644144191"), type: 'search'},
          {action: 'recentlySeen', name: i18n("searchfield.js660560315"), type: 'search'},
          {action: 'filterText', name: i18n("searchfield.js961730789"), type: 'filter'},
          {action: 'filter', name: i18n("searchfield.js867502466"), type: 'filter'},
      ];

  return <Paper elevation={1} style={{ display: 'flex', alignItems: 'center', width, maxHeight: window.innerWidth < 1200 ? 30 : 40, paddingLeft: 15, paddingRight: 5, borderRadius: 20, backgroundColor: 'white' }}>
    <Autocomplete
        style={{width: '100%'}}
      id="serchField"
      aria-label={i18n("search.js726564732")}
      options={options}
      onOpen={() => {
        let allOptions = startOptions.concat(model.getAll());
        setOptions(allOptions);
      }}
      getOptionLabel={(option) => getLabel(option)}
      renderInput={(params) => {
          const {InputLabelProps, InputProps, ...rest} = params;
          return <TextField aria-label={i18n("search.js726564732")} inputProps={{...params.InputProps}} rest={{...rest}} onExtendedSearch={()=>{blur();onExtendedSearch();}} onExtendedFilter={()=>{blur();onExtendedFilter();}} isFiltered={model.isFiltered()}/>
        }
      }
      onChange = {(evt, val, reason) => {
        if(val) {
          if (val.action) {
            switch (val.action) {
              case 'search':
                blur();
                onExtendedSearch();
                break;
              case 'filterText':
                blur();
                onExtendedFilter();
                break;
              case 'filter':
                blur();
                onExtendedFilter();
                break;
              case 'nearby':
                blur();
                onExtendedSearch('nearby');
                break;
              case 'positiontime':
                blur();
                onExtendedSearch('positiontime');
                break;
              case 'myhistories':
                blur();
                onExtendedSearch('myhistories');
                break;
              case 'recentlySeen':
                blur();
                onExtendedSearch('recentlySeen');
                break;
              default:;
            }
          } else {
            blur();
            val.id && onHighlight(val.id)
          }
        }
      }}
      onInputChange={(event, newInputValue, reason) => {
        if(reason !== 'reset') {
          onInputChange(newInputValue);
        }}}
      inputValue={inputValue}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.defaultMuiPrevented = true;
          blur();
          onExtendedSearch && onExtendedSearch();
        }
      }}
      renderOption={(option, { selected }) => (option.action ?
              <div style={{color: 'black', width: '100%', display: 'flex'}}>
                {option.type==='search' ? <SearchIcon/> : <FilterIcon/>}
                {getLabel(option)}
              </div>
              :
              <div style={{borderBottom: '1px solid lightgray', width: '100%'}}>
                {getLabel(option)}
              </div>
      )}
      filterOptions={(options) =>
          options.filter((option) => {
            if(option.action) {
              switch(option.action) {
                case 'search': return inputValue.trim().length > 0;
                case 'filterText': return model.size()>0 && inputValue.trim().length > 0;
                case 'filter': return model.size()>0 && inputValue.trim().length === 0;
                case 'nearby': return inputValue.trim().length === 0;
                case 'positiontime': return inputValue.trim().length === 0;
                case 'myhistories': return inputValue.trim().length === 0;
                case 'recentlySeen': return inputValue.trim().length === 0;
                default:;
              }
            }
            return getLabel(option).toLowerCase().includes(inputValue.toLowerCase())})
      }
      groupBy={(option) => option.action ? i18n("searchfield.js829902906") : i18n("searchfield.js807364549")}
      noOptionsText={"keine Auswahl"}
      PopperComponent={CustomPopper}
        ListboxProps={{ style: { maxHeight: 'calc(100vH - 200px)' }, position: "bottom-start"}}
  />
  </Paper>
}

export default SearchField;
