import React, {useState, useEffect} from 'react';
import Advertising from "./advertising";
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import Paper from '@material-ui/core/Paper';
import PricingButton from "../ui-components/buttons/text/pricingbutton";

let clickedAwayIDs = new Set();

const AdvertisingSnackBar = ({id, isResource, onShowPricing}) => {
  const [visible, setVisible] = useState(id && id!==0 && !clickedAwayIDs.has(id+"_"+isResource) && window.innerWidth > 800);

  const close = () => {
    setVisible(false);
    clickedAwayIDs.add(id+"_"+isResource);
  }

  return visible && <Paper elevation={5} style={{background: "#333333",  position: "fixed", left: "50%", right: "auto", bottom: 24, zIndex: "20000", alignItems: "center"}}>
    <IconButton style={{position: 'absolute', top: -25, right: -25}}><div style={{borderRadius: 30, background: "gray", height: 35, width: 35}}><CancelIcon fontSize="large" style={{color: "white"}} onClick={()=>close()}/></div></IconButton >
    <Advertising id={id} isResource={isResource}/>
    <div style={{position: 'absolute', bottom: -20, right: -35}}><PricingButton style={{minWidth: 130}} onClick={() => onShowPricing && onShowPricing(true)}/></div>
  </Paper>
}

export default AdvertisingSnackBar;
